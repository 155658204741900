@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Knewave:thin,regular,medium,semibold,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Inter:thin,regular,medium,semibold,bold,italic&subset=latin,latin-ext");

body {
  margin: 0;
  color: #161616;
  background-color: #d7c0a9;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-fonts {
  visibility: hidden;
}

h1 {
  font-size: 3rem;
  line-height: 100%;
  font-family: "Knewave", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
h2 {
  font-size: 2.5rem;
  line-height: 100%;
  font-family: "Knewave", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
h3 {
  font-size: 2rem;
  line-height: 100%;
  font-family: "Knewave", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

h4 {
  font-size: 1.5rem;
  line-height: 100%;
  font-family: "Knewave", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

@layer components {
  /* Required by Prefabs folder ---- */
  .btn {
    @apply font-semibold transition-all duration-75 ease-in-out flex w-fit items-center space-x-2 rounded-2xl cursor-pointer focus:ring-0 px-6 py-2.5 disabled:pointer-events-none disabled:opacity-70;
  }
  .icon-btn {
    @apply rounded-full transition-all duration-75 ease-in-out cursor-pointer focus:ring-0 p-2 disabled:pointer-events-none disabled:opacity-70;
  }
  .card {
    @apply relative rounded-3xl p-5;
  }

  /* Personalized ---- */
}
